<template>
    <v-sheet>
        <v-container class="py-16">
            <v-row class="d-flex justify-center text-center">
                <v-col cols="7" xl="12" lg="12" md="12" sm="6">
                    <v-img :src="require('../assets/pulse-services-logo.svg')" class="my-3" contain height="200" />
                </v-col>
    
                <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="mb-4 py-1 py-xl-12 py-lg-12 py-md-12 py-sm-12">
                    <h1 class="display-xl-2 headline font-weight-bold mb-3">
                        Découvrez notre gamme de services à destination des commerces
                    </h1>
    
                    <p class="subtitle-2 text-xl-h6 text-lg-h6 text-md-h6 text-sm-body-1 font-weight-regular">
                        Des services 
                        sur mesure et adaptés selon vos besoins.
    
                    </p>
                </v-col>
    
                <v-col cols="12" xl="12" lg="12" md="12" sm="9" class="mb-5">
                    <v-container fluid>
                        <v-row class="justify-center ">
                            <v-col cols="9" xl="2" lg="3" md="4" sm="4" v-for="(next, i) in whatsNexts" :key="i">
                                <router-link class="router-link" :to="next.path">
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="d-flex flex-column  align-center " width="240">
                                            <v-img contain max-height="300" :max-width="next.width" :src="next.src">
                                                <v-expand-transition >
                                                    <div
                                                        v-if="hover" color="agrume"
                                                        :class="next.class"
                                                        style="height: 100%; width=100%;"
                                                    >
                                                        <v-card-title class="mx-auto white--text">{{next.text}}</v-card-title>
                                                    </div>
                                                </v-expand-transition>
                                            </v-img>
                                        </v-card>
                                    </v-hover>
                                </router-link>
    
                            </v-col>
    
                        </v-row>
                    </v-container>
    
    
                </v-col>
    
    
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
export default {
    name: 'HelloWorld',

    data: () => ({

        whatsNexts: [{
                text: 'Presse-agrumes',
                path: '/agrume',
                src: require('../assets/img-agrume/app-signup.png'),
                width: '240',
                class: "agrume-hover img-hover d-flex text-center transition-fast-in-fast-out  v-card--reveal text-h2 ",
            },
            {
                text: 'Rôtissoires',
                path: '/rotisserie',
                src: require('../assets/img-rotisserie/grill/Combine-S6.png'),
                width: '240',
                class: "rotisserie-hover img-hover d-flex text-center transition-fast-in-fast-out  v-card--reveal text-h2 "
            },
            {
                text: 'Fours à pain',
                path: '/four',
                src: require('../assets/img-four/gala-prod1.png'),
                width: '240',
                class: "four-hover img-hover d-flex text-center transition-fast-in-fast-out  v-card--reveal text-h2 ",
            },
        ],
    }),
}
</script>

<style scoped>
.router-link {
    text-decoration: none;
}

.agrume-hover{
 background-color: #ff5622c7;
}

.rotisserie-hover{
    background-color:#d32f2fc7;
}
.four-hover{
    background-color:#ffa200c7;
}


</style>
